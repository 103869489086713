import React, { FC } from "react";

import { Button } from "../Button/Button";

import { ReactComponent as Check } from "../../assets/icons/16x16/check.svg";

import { BlockProps } from "./Block.types";

import styles from "./Block.module.scss";

export const Block: FC<BlockProps> = ({ attributes, button, header, subTitle, title, current }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapperPadding}>
                <p className={styles.header}>
                    {header.text} {header.subText && <span className={styles.subText}>{header.subText}</span>}
                </p>
                <p className={styles.title}>
                    <span className={styles.titleFirst}>{title.first}</span>
                    <span className={styles.titleSecond}>{title.second}</span>
                </p>
                <p className={styles.subTitle}>{subTitle}</p>
                <Button
                    fullWidth
                    onClick={button.action}
                    disabled={current}
                    variant={current ? "primary" : "secondary"}
                    icon={current ? <Check /> : undefined}
                    style={{ fontSize: "16px", height: "48px", fontWeight: 500 }}
                >
                    {button.text}
                </Button>
            </div>
            <div className={styles.divider} />

            <div className={styles.wrapperPadding}>
                <ul className={styles.attributesWrapper}>
                    {attributes.map((attribute, i) => {
                        return (
                            <li key={i} className={styles.attribute}>
                                <span className={styles.attributeIconWrapper}>
                                    <Check data-testid="checkIcon" className={styles.attributeIcon} />
                                </span>
                                {attribute}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
