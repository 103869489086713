import React, { useState } from "react";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Tooltip,
} from "@mui/material";

import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";

import { ReactComponent as Trash } from "../../assets/icons/16x16/trash.svg";
import { EmployeeFilters } from "../../containers/EmployeeFilters/EmployeeFilters";
import { AppDispatch } from "../../store/store";

import { BadgeWithLabel } from "../BadgeWithLabel/BadgeWithLabel";

import { ReactComponent as Plus } from "../../assets/icons/16x16/plus.svg";

import {
    removeEmployee,
    setCurrentEmployeeId,
    unassignEmployee,
} from "../../store/EmployeesManagmentPanel/EmployeesManagmentPanel.slice";

import { toggleAssignUserModal } from "../../store/Modal/modalSlice";

import { ManagmentPanelSingleUserData } from "../../api/types";

import { fetchEmployeeLevels } from "../../store/Competences/competencesSlice";
import { setMatrixId, setMatrixName } from "../../store/CurrentMatrix/currentMatrixSlice";

import { setId } from "../../store/CurrentTab/currentTabSlice";

import { fetchColumns } from "../../store/Kanban/Columns/Columns.slice";

import { CURRENT_TAB_ID } from "../../utils/managmentPanelUrls";

import styles from "./EmployeeTable.module.scss";
import { EmployeeTableProps } from "./EmployeeTable.types";

const handleEdit = (employee: ManagmentPanelSingleUserData, dispatch: AppDispatch) => {
    dispatch(toggleAssignUserModal({ isOpen: true, user: employee }));
};

const handleDelete = (userId: number, dispatch: AppDispatch) => {
    dispatch(removeEmployee(userId));
};

const handleCheckModel = (userId: number, dispatch: AppDispatch) => {
    // dispatch(setMatrixId(model.pk));
    dispatch(fetchColumns({ userId: userId }));
    dispatch(setCurrentEmployeeId(userId));
    dispatch(setId(CURRENT_TAB_ID.KANBAN));
};

const handleUnassignUser = (userEmail: string, modelId: number, dispatch: AppDispatch) => {
    dispatch(unassignEmployee({ userEmail: userEmail, matrixId: modelId }));
};

const handleEnterEmployeeMatrix = (
    employeeId: number,
    model: {
        pk: number;
        name: string;
    },
    dispatch: AppDispatch,
) => {
    dispatch(fetchEmployeeLevels({ userId: employeeId, id: model.pk }));
    dispatch(setMatrixId(model.pk));
    dispatch(setMatrixName(model.name));
    dispatch(setId(CURRENT_TAB_ID.MATRIX));
};

export const EmployeeTable: React.FC<EmployeeTableProps> = ({ employees, matrixes }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const [inputFilter, setInputFilter] = useState<string>("");
    const [positionFilter, setPositionFilter] = useState<string>("");
    const [levelFilter, setLevelFilter] = useState<string>("");

    const filteredEmployees = employees.filter((employee) => {
        return (
            (employee.first_name.toLowerCase().includes(inputFilter.toLowerCase()) ||
                employee.last_name.toLowerCase().includes(inputFilter.toLowerCase())) &&
            (positionFilter === "" ||
                (employee.assign_matrix_list.length > 0 &&
                    employee.assign_matrix_list.some((matrix) =>
                        matrix.name.toLowerCase().includes(positionFilter.toLowerCase()),
                    )))
        );
    });

    return (
        <TableContainer component={Paper} sx={{ borderRadius: "12px", marginTop: "24px" }}>
            <EmployeeFilters
                inputFilter={inputFilter}
                setInputFilter={setInputFilter}
                positionFilter={positionFilter}
                setPositionFilter={setPositionFilter}
                levelFilter={levelFilter}
                setLevelFilter={setLevelFilter}
                positions={matrixes}
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={styles.tableCell}>{t("managmentPanel.table.columns.employee")}</TableCell>
                        <TableCell className={styles.tableCell}>{t("managmentPanel.table.columns.email")}</TableCell>
                        <TableCell className={styles.tableCell}>{t("managmentPanel.table.columns.models")}</TableCell>
                        <TableCell className={styles.tableCell}>{t("managmentPanel.table.columns.actions")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredEmployees.map((employee, i) => (
                        <TableRow key={employee.email} style={{ backgroundColor: i % 2 === 0 ? "#F9FAFB" : "#ffffff" }}>
                            <TableCell>
                                <p className={styles.namesContainer}>
                                    <span>{`${employee.first_name} ${employee.last_name}`}</span>
                                    <span className={styles.role}>{employee.level}</span>
                                </p>
                            </TableCell>
                            <TableCell>{employee.email}</TableCell>
                            <TableCell>
                                <div className={styles.modelsContainer}>
                                    {employee.assign_matrix_list.map((model, i) => {
                                        return (
                                            <BadgeWithLabel
                                                key={i}
                                                label={employee.level}
                                                text={model.name}
                                                textAction={() =>
                                                    handleEnterEmployeeMatrix(employee.pk, model, dispatch)
                                                }
                                                handleAction={() =>
                                                    handleUnassignUser(employee.email, model.pk, dispatch)
                                                }
                                            />
                                        );
                                    })}
                                    {employee.assign_matrix_list.length < 2 && (
                                        <Tooltip title={t("managmentPanel.table.tooltips.assignModel")}>
                                            <IconButton
                                                onClick={() => handleEdit(employee, dispatch)}
                                                aria-label="edit"
                                                sx={{ padding: 0 }}
                                            >
                                                <Plus style={{ color: "#868C98" }} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={t("managmentPanel.table.tooltips.checkKanbanModel")}>
                                    <IconButton
                                        aria-label="checkKanbanModel"
                                        onClick={() => handleCheckModel(employee.pk, dispatch)}
                                    >
                                        <PersonalVideoIcon sx={{ width: "16px" }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t("managmentPanel.table.tooltips.deleteUser")}>
                                    <IconButton aria-label="delete" onClick={() => handleDelete(employee.pk, dispatch)}>
                                        <Trash />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
