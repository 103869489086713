import React, { FC, useEffect } from "react";

import useWebSocket from "react-use-websocket";

import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { WS_URLS } from "../../../api/urls";

import { AppDispatch, RootState } from "../../../store/store";

import { clearBadges, setBadges, setBadgesLoading } from "../../../store/AiMatrix/aiBadgesSlice";
import { showNotification } from "../../../store/Notification/notificationSlice";
import { clearAreas } from "../../../store/AiMatrix/aiAreasSlice";
import { clearMatrix } from "../../../store/AiMatrix/createMatrixSlice";
import { refreshAccessToken } from "../../../utils/wsRetryHelper";
import { setTokens } from "../../../store/Auth_User/authSlice";

export const Badges: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { user } = useSelector((state: RootState) => state.auth);
    const { loading } = useSelector((state: RootState) => state.aiBadges);
    const { data } = useSelector((state: RootState) => state.aiMatrix);

    const handleReconnectStop = () => {
        dispatch(
            showNotification({
                variant: "error",
                title: "Error!",
                subtitle: "Wystąpił błąd, spróbuj ponownie.",
            }),
        );
        dispatch(setBadgesLoading(false));
        dispatch(clearBadges());
        dispatch(clearAreas());
        dispatch(clearMatrix());
        navigate("/matrixes");
    };

    const { sendJsonMessage, lastJsonMessage, getWebSocket } = useWebSocket(
        `wss://dev-api-macierze.pluckyrebels.com/${WS_URLS.MATRIX}${data.id}/attitude/?authorization=${user.access}`,
        // `${window.APP_CONFIG.REACT_APP_WS_BASEURL}/${WS_URLS.MATRIX}${data.id}/attitude/?authorization=${user.access}`,
        {
            onOpen: () => {
                sendJsonMessage({});
            },
            onError: async (e) => {
                console.log("Error badges", e);
                try {
                    const newUserData = await refreshAccessToken(user.refresh);
                    dispatch(setTokens(newUserData));
                } catch (error) {
                    console.error("Error refreshing access token:", error);
                }
            },

            retryOnError: false,
            reconnectAttempts: 5,
            shouldReconnect: () => true,
            onReconnectStop: () => handleReconnectStop(),
        },
    );

    useEffect(() => {
        dispatch(setBadgesLoading(true));
        if (lastJsonMessage) {
            dispatch(setBadges(lastJsonMessage));
            dispatch(setBadgesLoading(false));
            getWebSocket()?.close();
        }
    }, [lastJsonMessage]);

    return <div>{loading && <CircularProgress data-testid="circularProgress" />}</div>;
};
