import React, { FC } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Navbar } from "../../components/Navbar/Navbar";
import { CreateMatrixFromTemplate } from "../../components/CreateMatrixFromTemplate/CreateMatrixFromTemplate";
import { ActionButton } from "../../components/Navbar/Navbar.types";

export const TemplatesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleBack = () => {
        navigate("/matrixes");
    };

    const actionButtons: ActionButton[] = [
        {
            text: t("globals.back"),
            action: handleBack,
            tooltip: t("globals.backTooltip"),
        },
    ];

    return (
        <>
            <Navbar actionButtons={actionButtons} />
            <CreateMatrixFromTemplate />
        </>
    );
};
