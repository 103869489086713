import React, { FC, useEffect, useState } from "react";

import { TextField } from "@mui/material";

import dayjs from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { useTranslation } from "react-i18next";

import { GoalData, Goal as GoalType } from "../../../api/types";

import { Button } from "../../Button/Button";

import Modal from "../../Modal/Modal";

import styles from "./EditGoalModal.module.scss";
import { EditGoalModalProps } from "./EditGoalModal.types";

export const EditGoalModal: FC<EditGoalModalProps> = ({ goal, handleSubmit, isOpen, toggleOpen, setEditedGoal }) => {
    const { t } = useTranslation();

    const maxLength = 200;
    const [charactersLeft, setCharactersLeft] = useState<number>(maxLength);

    useEffect(() => {
        setCharactersLeft(maxLength - goal?.development_goal.length);
    }, [goal]);

    const handleEditConfirm = async () => {
        if (!goal) return;
        handleSubmit(goal);
        toggleOpen();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        //@ts-ignore
        setEditedGoal((prevGoal: GoalType | GoalData) => ({
            ...prevGoal,
            development_goal: newValue,
        }));
        if (newValue.length <= maxLength) {
            setCharactersLeft(maxLength - newValue.length);
        }
    };

    return (
        <Modal
            actionButtons={{
                onClose: {
                    action: () => {
                        toggleOpen();
                        setEditedGoal(undefined);
                    },
                },
                onSubmit: (
                    <Button onClick={handleEditConfirm} variant="secondary">
                        {t("kanbanPage.editGoalModal.save")}
                    </Button>
                ),
            }}
            isOpen={isOpen}
            title={t("kanbanPage.editGoalModal.title")}
            subTitle={t("kanbanPage.editGoalModal.setGoal")}
        >
            <div>
                <TextField
                    multiline
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                                borderColor: "#7F56D9",
                            },
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                            color: "#7F56D9",
                        },
                    }}
                    inputProps={{
                        maxLength: maxLength,
                        style: {
                            height: "130px",
                        },
                    }}
                    value={goal.development_goal}
                    className={styles.editableTextField}
                    onChange={handleInputChange}
                    name="Cel"
                />
                <p className={styles.charactersCounter} data-testid="charactersLeft">
                    {`${t("kanbanPage.editGoalModal.charsLeft")}: ${charactersLeft}`}
                </p>
                <p className={styles.deadline}>{t("kanbanPage.editGoalModal.deadline")}</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        className={styles.picker}
                        slotProps={{
                            textField: {
                                sx: {
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#7F56D9",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#7F56D9",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#7F56D9",
                                        },
                                    },
                                },
                            },
                            day: {
                                sx: {
                                    "&.MuiPickersDay-root.Mui-selected": {
                                        backgroundColor: "#7F56D9",
                                    },
                                },
                            },
                        }}
                        value={dayjs(goal.deadline)}
                        onChange={(e) => e && setEditedGoal({ ...goal, deadline: dayjs(e).format("YYYY-MM-DD") })}
                    />
                </LocalizationProvider>
            </div>
        </Modal>
    );
};
