import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { isAxiosError } from "axios";

import { createAiArea, deleteAiArea, editAiArea, regenerateAiArea } from "../../api/requests/requests";
import { CompetencesArea } from "../../api/types";
import { showNotification } from "../Notification/notificationSlice";

export const editArea = createAsyncThunk(
    "aiAreas/editAreas",
    async (data: { id: number; area: CompetencesArea }, { dispatch }) => {
        try {
            return await editAiArea(data.id, data.area);
        } catch (error) {
            let errorMsg = "Edycja zakończona niepowodzeniem.";
            if (isAxiosError(error) && error?.response?.data.detail) {
                errorMsg = error.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(error);
            throw error;
        }
    },
);

export const deleteArea = createAsyncThunk(
    "aiAreas/deleteArea",
    async (data: { id: number; area: CompetencesArea }, { dispatch }) => {
        try {
            return await deleteAiArea(data.id, data.area);
        } catch (error) {
            let errorMsg = "Usuwanie obszaru zakończone niepowodzeniem.";
            if (isAxiosError(error) && error?.response?.data.detail) {
                errorMsg = error.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(error);
            throw error;
        }
    },
);

export const createArea = createAsyncThunk(
    "aiAreas/createArea",
    async (data: { id: number; areaName: string }, { dispatch }) => {
        try {
            const res = await createAiArea(data.id, data.areaName);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Generowanie obszaru zakończone powodzeniem.",
                }),
            );
            return res;
        } catch (error) {
            let errorMsg = "Edycja obszaru zakończona niepowodzeniem.";
            if (isAxiosError(error) && error?.response?.data.detail) {
                errorMsg = error.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(error);
            throw error;
        }
    },
);

export const regenerateArea = createAsyncThunk(
    "aiAreas/regenerateArea",
    async (data: { id: number; desc: string }, { dispatch }) => {
        try {
            const res = await regenerateAiArea(data.id, data.desc);
            dispatch(
                showNotification({
                    variant: "success",
                    title: "Sukces!",
                    subtitle: "Regenerowanie obszaru zakończone powodzeniem.",
                }),
            );
            return res;
        } catch (error) {
            let errorMsg = "Regenerowanie zakończone niepowodzeniem.";
            if (isAxiosError(error) && error?.response?.data.detail) {
                errorMsg = error.response.data.detail;
            }
            dispatch(
                showNotification({
                    variant: "error",
                    title: "Error!",
                    subtitle: errorMsg,
                }),
            );
            console.log(error);
            throw error;
        }
    },
);

export interface AiAreasState {
    areas: CompetencesArea[];
    loading: boolean;
    error?: string;
    hasWebsocketError?: boolean;
}

export const initialState: AiAreasState = {
    areas: [],
    loading: false,
};

const aiAreasSlice = createSlice({
    name: "aiAreas",
    initialState,
    reducers: {
        clearAreas: (state) => {
            state.areas = [];
            state.loading = false;
        },
        setAreas: (state, action: PayloadAction<CompetencesArea[]>) => {
            state.areas = action.payload;
        },
        setAreasLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setIsWebsocketError: (state, action: PayloadAction<boolean>) => {
            state.hasWebsocketError = action.payload;
        }, // FUTURE
    },
    extraReducers: (builder) => {
        builder
            .addCase(editArea.pending, (state) => {
                state.loading = true;
            })
            .addCase(editArea.fulfilled, (state, action) => {
                state.loading = false;
                state.areas = state.areas.map((area) => {
                    if (area.pk === action.payload.pk) {
                        return action.payload;
                    }
                    return area;
                });
            })
            .addCase(editArea.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(deleteArea.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteArea.fulfilled, (state, action) => {
                state.loading = false;
                state.areas = state.areas.filter((area) => area.pk !== action.meta.arg.area.pk);
            })
            .addCase(deleteArea.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(regenerateArea.pending, (state) => {
                state.loading = true;
            })
            .addCase(regenerateArea.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(regenerateArea.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createArea.pending, (state) => {
                state.loading = true;
            })
            .addCase(createArea.fulfilled, (state, action) => {
                state.loading = false;
                state.areas = [...state.areas, action.payload];
            })
            .addCase(createArea.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearAreas, setAreas, setAreasLoading, setIsWebsocketError } = aiAreasSlice.actions;
export default aiAreasSlice.reducer;
