import React, { FC, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CircularProgress, InputLabel, TextField } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { AppDispatch, RootState } from "../../store/store";
import { copyModelFromTemplate, fetchTemplates } from "../../store/Templates/templatesSlice";

import { Button } from "../Button/Button";

import { Template } from "../../api/types";

import { setIsTemplate, setMatrixId, setMatrixName } from "../../store/CurrentMatrix/currentMatrixSlice";

import Modal from "../Modal/Modal";

import styles from "./CreateMatrixFromTemplate.module.scss";

export const CreateMatrixFromTemplate: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
    const [inputValue, setInputValue] = useState<string>("");
    const { loading, templates } = useSelector((state: RootState) => state.templates);
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        dispatch(fetchTemplates());
    }, []);

    const handleAddNewModel = async (template: Template | null) => {
        if (!template || !inputValue) return null;
        const modifiedTemplate: Template = {
            ...template,
            name: inputValue,
        };
        await dispatch(copyModelFromTemplate({ template: modifiedTemplate })).then((e) => {
            if (e.type === "templates/copyModelFromTemplate/fulfilled") {
                navigate("/matrixes");
            }
        });
    };

    return (
        <div className={styles.wrapper}>
            <ul className={styles.templatesList}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    templates.map((template, i) => {
                        return (
                            <li key={i} className={styles.singleModelWrapper}>
                                <p className={styles.templateName}>{template.name}</p>
                                <p className={styles.templateName}>{template.descriptions}</p>
                                <div className={styles.buttonsWrapper}>
                                    <Button
                                        variant="secondary"
                                        onClick={() => {
                                            setCurrentTemplate(template);
                                            setInputValue(template.name);
                                        }}
                                    >
                                        {t("createModelFromTemplate.copy")}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={() => {
                                            dispatch(setMatrixId(template.id));
                                            dispatch(setMatrixName(template.name));
                                            dispatch(setIsTemplate(true));
                                            navigate("/matrixes");
                                        }}
                                    >
                                        {t("createModelFromTemplate.checkModel")}
                                    </Button>
                                </div>
                            </li>
                        );
                    })
                )}
            </ul>
            <Modal
                actionButtons={{
                    onClose: {
                        action: () => setCurrentTemplate(null),
                    },
                    onSubmit: (
                        <Button
                            variant="secondary"
                            onClick={() => handleAddNewModel(currentTemplate)}
                            disabled={inputValue.length < 1}
                        >
                            {t("createModelFromTemplate.copy")}
                        </Button>
                    ),
                }}
                isOpen={!!currentTemplate}
                title={t("createModelFromTemplate.copy")}
            >
                <div>
                    <InputLabel id="email-label" className={styles.label}>
                        {t("createModelFromTemplate.label")} ✏️
                    </InputLabel>
                    <TextField
                        id="custom-input"
                        variant="outlined"
                        value={inputValue}
                        onChange={handleInputChange}
                        sx={{
                            "& .MuiInputBase-input": {
                                padding: "10px 14px",
                                height: "24px",
                            },
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: "#7F56D9",
                                },
                            },
                        }}
                    />
                </div>
            </Modal>
        </div>
    );
};
