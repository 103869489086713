import React, { FC } from "react";

import { Link as RouterLink, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { ReactComponent as Logo } from "../../assets/pngs/logo.svg";

import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

import styles from "./ResetPasswordForm.module.scss";
import { StepOne } from "./StepOne/StepOne";
import { StepTwo } from "./StepTwo/StepTwo";

export const ResetPasswordForm: FC = () => {
    const { t } = useTranslation();
    const { "*": id } = useParams();

    return (
        <div className={styles.wrapper}>
            <div className={styles.languageSwitcherWrapper}>
                <LanguageSwitcher />
            </div>
            <a href={window.APP_CONFIG.REACT_APP_LANDING_PAGE}>
                <Logo className={styles.logo} />
            </a>
            <div className={styles.formContainer}>
                {id ? <StepTwo id={id} /> : <StepOne />}
                <p className={styles.registerText}>
                    {t("registerPage.scentence")}{" "}
                    <RouterLink to="/login" className={styles.registerLink}>
                        {t("globals.login")}
                    </RouterLink>
                </p>
            </div>
        </div>
    );
};
