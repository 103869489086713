import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Competence, ManagmentPanelSingleUserData } from "../../api/types";

export interface ModalState {
    isOpen: boolean;
    regenerateCompetenceModalOpen: {
        isOpen: boolean;
        item: Competence;
    };
    item: Competence;
    assignUserModalOpen: {
        isOpen: boolean;
        user: ManagmentPanelSingleUserData;
    };
    editObszaryModal: {
        isOpen: boolean;
    };
}

export const initialState: ModalState = {
    isOpen: false,
    assignUserModalOpen: {
        isOpen: false,
        user: {} as ManagmentPanelSingleUserData,
    },
    editObszaryModal: {
        isOpen: false,
    },
    regenerateCompetenceModalOpen: { isOpen: false, item: {} as Competence },
    item: { column_index: -999, level: -999, row_index: -999 },
};

const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        toggleModal: (state, action) => {
            state.item = action.payload;
            state.isOpen = !state.isOpen;
        },
        toggleEditObszaryModal: (state) => {
            state.editObszaryModal.isOpen = !state.editObszaryModal.isOpen;
        },
        toggleAssignUserModal: (
            state,
            action: PayloadAction<{
                isOpen: boolean;
                user: ManagmentPanelSingleUserData;
            }>,
        ) => {
            state.assignUserModalOpen = action.payload;
        },
        toggleRegenerateCompetenceModal: (
            state,
            action: PayloadAction<{
                isOpen: boolean;
                item: Competence;
            }>,
        ) => {
            state.regenerateCompetenceModalOpen.isOpen = action.payload.isOpen;
            state.regenerateCompetenceModalOpen.item = action.payload.item;
        },
        setText: (state, action) => {
            state.item.description = action.payload;
        },
    },
});

export const { toggleModal, setText, toggleAssignUserModal, toggleRegenerateCompetenceModal, toggleEditObszaryModal } =
    modalSlice.actions;
export default modalSlice.reducer;
