import React, { FC } from "react";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { CreateMatrix } from "../../components/CreateMatrix/CreateMatrix";

import { Navbar } from "../../components/Navbar/Navbar";

import { ActionButton } from "../../components/Navbar/Navbar.types";

export const CreatePage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleBack = () => {
        navigate("/matrixes");
    };

    const actionButtons: ActionButton[] = [
        {
            text: t("globals.back"),
            action: handleBack,
            tooltip: t("globals.backTooltip"),
        },
    ];
    return (
        <div>
            <Navbar actionButtons={actionButtons} />
            <CreateMatrix />
        </div>
    );
};
