import React, { FC } from "react";

import { useDispatch } from "react-redux";

import { useNavigate, Link as RouterLink } from "react-router-dom";

import { Formik, Form, Field, FieldProps } from "formik";

import { InputLabel, TextField } from "@mui/material";

import * as Yup from "yup";

import { useTranslation } from "react-i18next";

import { AppDispatch } from "../../store/store";
import { loginAsync } from "../../store/Auth_User/authSlice";

import { getUser } from "../../store/Auth_User/userSlice";

import { Button } from "../Button/Button";
import { ReactComponent as Logo } from "../../assets/pngs/logo.svg";

import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

import styles from "./LoginForm.module.scss";

export const LoginForm: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        email: Yup.string().required(t("globals.validation.required")),
        password: Yup.string().required(t("globals.validation.required")),
    });

    const handleSubmit = async (values: { email: string; password: string }) => {
        const loginResult = await dispatch(loginAsync(values));
        if (loginResult.meta.requestStatus === "fulfilled" && loginResult.payload) {
            dispatch(getUser());
            navigate("/matrixes");
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.languageSwitcherWrapper}>
                <LanguageSwitcher />
            </div>
            <a href={window.APP_CONFIG.REACT_APP_LANDING_PAGE}>
                <Logo className={styles.logo} />
            </a>
            <div className={styles.formContainer}>
                <Formik
                    initialValues={{ email: "", password: "" }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {() => (
                        <Form className={styles.form}>
                            <p className={styles.title}>{t("globals.login")}</p>
                            <Field name="email" data-testid="emailInput">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <InputLabel id="email-label" className={styles.label}>
                                            {t("loginPage.email")}
                                        </InputLabel>
                                        <TextField
                                            {...field}
                                            id="email-label"
                                            placeholder="email@email.com"
                                            error={meta.touched && Boolean(meta.error)}
                                            helperText={meta.touched && meta.error}
                                            className={styles.textField}
                                            sx={{
                                                "& .MuiInputBase-input": {
                                                    padding: "10px 14px",
                                                    height: "24px",
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#7F56D9",
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                            </Field>
                            <Field name="password" data-testid="passwordInput">
                                {({ field, meta }: FieldProps) => (
                                    <div>
                                        <InputLabel id="password-label" className={styles.label}>
                                            {t("loginPage.password")}
                                        </InputLabel>
                                        <TextField
                                            {...field}
                                            id="password-label"
                                            placeholder="*******"
                                            autoComplete="current-password"
                                            type="password"
                                            error={meta.touched && Boolean(meta.error)}
                                            helperText={meta.touched && meta.error}
                                            className={styles.textField}
                                            sx={{
                                                "& .MuiInputBase-input": {
                                                    padding: "10px 14px",
                                                    height: "24px",
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#7F56D9",
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                            </Field>
                            <div className={styles.loginButtonContainer}>
                                <Button variant="secondary" size="medium" fullWidth type="submit">
                                    {t("globals.login")}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <p className={styles.registerText}>
                    {t("loginPage.scentence")}{" "}
                    <RouterLink to="/register" className={styles.registerLink}>
                        {t("loginPage.register")}
                    </RouterLink>
                </p>
                <p className={styles.registerText}>
                    {t("loginPage.lostAccount")}{" "}
                    <RouterLink to="/reset" className={styles.registerLink}>
                        {t("loginPage.resetPassword")}
                    </RouterLink>
                </p>
            </div>
        </div>
    );
};
