import React, { FC, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Layout } from "../../components/Layout/Layout";
import { Matrix } from "../../components/Matrix/Matrix";

import { Navbar } from "../../components/Navbar/Navbar";

import { MatrixSummary } from "../../containers/MatrixSummary/MatrixSummary";

import { ActionButton } from "../../components/Navbar/Navbar.types";

import { clearData, setMatrixId } from "../../store/CurrentMatrix/currentMatrixSlice";
import { clearData as clearGoalData } from "../../store/Kanban/Goals/Goals.slice";
import { setEmployee } from "../../store/CurrentTab/currentTabSlice";
import { AppDispatch, RootState } from "../../store/store";

export const MatrixPage: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.userStore);
    const { id, isTemplate } = useSelector((state: RootState) => state.currentMatrix);
    const [isEmployeeDatasOpen, setEmployeeDatasOpen] = useState<boolean>(false);

    const handleBack = () => {
        dispatch(clearData());
        dispatch(setEmployee(undefined));
        if (isEmployeeDatasOpen) {
            setEmployeeDatasOpen(false);
        }
        if (isTemplate) {
            navigate("/clone");
        }
    };

    const handleGoKanban = () => {
        navigate("/kanban");
    };

    const handleGoKanbanFromMain = () => {
        // https://pluckyrebels.atlassian.net/jira/software/projects/CE/boards/4?selectedIssue=CE-1184
        // LATER REMOVE IT, QUICK FIX FOR NOW
        dispatch(clearGoalData());
        dispatch(setMatrixId(null));
        navigate("/kanban");
    };

    const handleGoManagment = () => {
        navigate("/managment");
    };

    const handleAssignment = () => {
        setEmployeeDatasOpen(!isEmployeeDatasOpen);
    };

    // TEMPORARY BUTTONS (for streamlit apps redirects)
    const handleGoCourseMatch = () => {
        window.location.href = "https://app.outcomeskills.com/os-course-match/";
    };

    const handleGoJobOffer = () => {
        window.location.href = "https://app.outcomeskills.com/os-job-offer/";
    };
    // TEMPORARY BUTTONS END

    const actionButtons: ActionButton[] = [
        {
            text: t("globals.back"),
            action: handleBack,
            tooltip: t("globals.backTooltip"),
        },
        user.is_organization_admin && !isTemplate
            ? {
                  text: t("globals.managmentPanel"),
                  action: handleGoManagment,
                  tooltip: t("globals.managmentPanelTooltip"),
              }
            : null,
        !isTemplate
            ? {
                  text: t("globals.kanban"),
                  action: handleGoKanban,
                  tooltip: t("globals.kanbanTooltip"),
              }
            : null,
        user.is_organization_admin && !isTemplate
            ? {
                  text: t("globals.assignModal"),
                  action: handleAssignment,
                  tooltip: t("globals.assignModalTooltip"),
              }
            : null,
    ].filter((button) => button !== null) as ActionButton[];

    const actionButtonsSecond: ActionButton[] = [
        user.is_organization_admin
            ? {
                  text: t("globals.managmentPanel"),
                  action: handleGoManagment,
                  tooltip: t("globals.managmentPanelTooltip"),
              }
            : null,
        {
            text: t("globals.kanban"),
            action: handleGoKanbanFromMain,
            tooltip: t("globals.kanbanTooltip"),
        },
        // TEMPORARY BUTTONS (for streamlit apps redirects)
        {
            text: t("globals.courseMatch"),
            action: handleGoCourseMatch,
            tooltip: t("globals.courseMatchTooltip"),
        },
        {
            text: t("globals.jobOffer"),
            action: handleGoJobOffer,
            tooltip: t("globals.jobOfferTooltip"),
        },
        // TEMPORARY BUTTONS END
    ].filter((button) => button !== null) as ActionButton[];

    return (
        <>
            <Navbar
                actionButtons={id ? actionButtons : actionButtonsSecond}
                employeeDatas={{ action: handleAssignment, isOpen: isEmployeeDatasOpen }}
            />
            <Layout>
                <MatrixSummary />
                <Matrix />
            </Layout>
        </>
    );
};
