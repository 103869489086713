import axios from "axios";

import { URLS } from "../api/urls";

export const refreshAccessToken = async (userRefreshToken: string) => {
    try {
        const res = await axios.post(`https://dev-api-macierze.pluckyrebels.com${URLS.REFRESH_TOKEN}`, {
            // const res = await axios.post(`${window.APP_CONFIG.REACT_APP_API_BASEURL}${URLS.REFRESH_TOKEN}`, {
            refresh: userRefreshToken,
        });
        const newUserData = {
            access: res.data.access,
            refresh: res.data.refresh,
        };
        localStorage.setItem("user", JSON.stringify(newUserData));
        return newUserData;
    } catch (error) {
        console.error("Error refreshing access token:", error);
        throw error;
    }
};
