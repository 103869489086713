import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { CircularProgress, TextField } from "@mui/material";

import Modal from "../Modal/Modal";
import { AppDispatch, RootState } from "../../store/store";
import { toggleEditObszaryModal } from "../../store/Modal/modalSlice";
import { Button } from "../Button/Button";
import { deleteArea, editArea, regenerateArea } from "../../store/AiMatrix/aiAreasSlice";
import { CompetencesArea, MatrixGenerationStatus } from "../../api/types";

import { fetchRoles, getGenerationStatus, setGenerationStatus } from "../../store/CurrentMatrix/currentMatrixSlice";

import { clearCompetences, fetchLevels } from "../../store/Competences/competencesSlice";

import styles from "./EditObszaryModal.module.scss";

const EditObszaryModal: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { roles, rolesLoading, id } = useSelector((state: RootState) => state.currentMatrix);
    const { loading } = useSelector((state: RootState) => state.aiAreas);
    const { isOpen } = useSelector((state: RootState) => state.modal.editObszaryModal);
    const { t } = useTranslation();
    const [editedCompetence, setEditedCompetence] = useState<null | CompetencesArea>(null);

    const handleEditConfirm = () => {
        if (!editedCompetence || !id) return;
        dispatch(editArea({ area: editedCompetence, id: id })).then(() => dispatch(fetchRoles(id))); // Akceptowalne rozwiązanie
        setEditedCompetence(null);
    };

    const handleRegenerateConfirm = () => {
        if (!editedCompetence || !id) return;
        dispatch(deleteArea({ area: editedCompetence, id: id })).then(() =>
            dispatch(regenerateArea({ id: id, desc: editedCompetence.name })).then(() => {
                dispatch(fetchRoles(id));
                dispatch(getGenerationStatus({ matrixId: id })).then((e) => {
                    dispatch(clearCompetences());
                    dispatch(setGenerationStatus(e.payload as MatrixGenerationStatus));
                });
            }),
        ); // Akceptowalne rozwiązanie
        setEditedCompetence(null);
    };

    return (
        <Modal
            isOpen={isOpen}
            actionButtons={{
                onClose: { action: () => dispatch(toggleEditObszaryModal()) },
                onSubmit: (
                    <Button variant="secondary" onClick={() => dispatch(toggleEditObszaryModal())}>
                        {t("matrixPage.editObszaryModal.close")}
                    </Button>
                ),
            }}
            title={t("matrixPage.editObszaryModal.title")}
            subTitle={t("matrixPage.editObszaryModal.description")}
        >
            <ul className={styles.rolesList}>
                {rolesLoading || loading ? (
                    <CircularProgress />
                ) : (
                    roles.map((role) => (
                        <li
                            key={role.pk}
                            style={
                                editedCompetence && editedCompetence.pk === role.pk
                                    ? {}
                                    : {
                                          backgroundColor: role.background_color,
                                          borderColor: role.border_color,
                                          color: role.text_color,
                                      }
                            }
                            className={styles.singleElementWrapper}
                        >
                            {editedCompetence && editedCompetence.pk === role.pk ? (
                                <div>
                                    <TextField
                                        label={t("createModel.editable")}
                                        value={editedCompetence.name}
                                        className={styles.editableTextField}
                                        style={{
                                            backgroundColor: role.background_color,
                                            borderColor: role.border_color,
                                            color: role.text_color,
                                        }}
                                        onChange={(e) =>
                                            setEditedCompetence({ ...editedCompetence, name: e.target.value })
                                        }
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: "#7F56D9",
                                                },
                                            },
                                            "& .MuiInputLabel-root.Mui-focused": {
                                                color: "#7F56D9",
                                            },
                                        }}
                                    />
                                    <div className={styles.actionContainer}>
                                        <Button
                                            onClick={() => setEditedCompetence(null)}
                                            type="button"
                                            variant="helper"
                                            size="small"
                                        >
                                            {t("createModel.cancel")}
                                        </Button>
                                        <Button
                                            onClick={handleRegenerateConfirm}
                                            type="button"
                                            variant="helper"
                                            size="small"
                                        >
                                            {t("matrixPage.regenerate")}
                                        </Button>
                                        <Button onClick={handleEditConfirm} type="button" variant="helper" size="small">
                                            {t("createModel.save")}
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div onClick={() => setEditedCompetence(role)}>{role.name}</div>
                            )}
                        </li>
                    ))
                )}
            </ul>
        </Modal>
    );
};

export default EditObszaryModal;
