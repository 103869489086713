import React, { FC, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CircularProgress, Tooltip } from "@mui/material";

import { useTranslation } from "react-i18next";

import { toggleModal, toggleRegenerateCompetenceModal } from "../../store/Modal/modalSlice";

import { AppDispatch, RootState } from "../../store/store";

import {
    deleteSingleCompetence,
    duplicateSingleCompetence,
    learnSingleCompetence,
    markSingleCompetence,
} from "../../store/Competences/competencesSlice";
import { ReactComponent as Trash } from "../../assets/icons/16x16/trash.svg";
import { ReactComponent as Edit } from "../../assets/icons/16x16/edit.svg";
import { ReactComponent as Copy } from "../../assets/icons/16x16/copy.svg";
import { ReactComponent as Plus } from "../../assets/icons/16x16/plus.svg";
import { ReactComponent as Certificate } from "../../assets/icons/16x16/certificate.svg";
import { ReactComponent as Check } from "../../assets/icons/16x16/check.svg";
import { ReactComponent as Flag } from "../../assets/icons/16x16/flag.svg";

import { Attitude } from "../../api/types";

import { TruncatedTextWithTooltip } from "../TruncatedTextWithTooltip/TruncatedTextWithTooltip";

import { Button } from "../Button/Button";

import { TileProps } from "./Tile.types";
import styles from "./Tile.module.scss";
import { Action } from "./Menu/Menu.types";
import { Header } from "./Header/Header";
import { AddTileModal } from "./AddTileModal/AddTileModal";

const Tile: FC<TileProps> = ({ item, is_last_one }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const { roles, areas: areasArray, id, isTemplate } = useSelector((state: RootState) => state.currentMatrix);
    const { user } = useSelector((state: RootState) => state.userStore);
    const { courses: coursesObject } = useSelector((state: RootState) => state.courses);

    const { description, area, attitudes, pk, is_empty, is_editing } = item;
    const [showAddTileModal, setShowAddTileModal] = useState<boolean>(false);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [showFlagMenu, setShowFlagMenu] = useState<boolean>(false);

    const matchedBadges = attitudes?.map((item) => {
        const matchingElement = areasArray?.find((element) => element.pk === item);
        return matchingElement ? { ...matchingElement } : null;
    }) as Attitude[];

    const getColor = () => {
        const colors = roles.find((role) => role.pk === area);
        return {
            gradiend: `linear-gradient(${colors?.background_color}, #FFF 100%)`,
            color: colors?.border_color,
        };
    };

    const handleEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        dispatch(toggleModal(item));
    };

    const handleCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!id || !item.pk) return;
        e.stopPropagation();
        dispatch(duplicateSingleCompetence({ matrixId: id, id: item.pk }));
    };

    const handleRegenerate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!id || !item.pk) return;
        e.stopPropagation();
        dispatch(toggleRegenerateCompetenceModal({ isOpen: true, item: item }));
    };

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (!id) return;
        dispatch(
            deleteSingleCompetence({
                item: item,
                matrixId: id,
            }),
        );
    };

    const menuItems: Action[] = [
        {
            name: t("matrixPage.edit"),
            action: handleEdit,
            icon: <Edit />,
            dataTestid: "editButton",
        },
        {
            name: t("matrixPage.duplicate"),
            action: handleCopy,
            icon: <Copy />,
            dataTestid: "duplicateButton",
        },
        {
            name: t("matrixPage.regenerate"),
            action: handleRegenerate,
            icon: <Edit />,
            dataTestid: "regenerateButton",
        },
        {
            name: t("matrixPage.delete"),
            action: handleDelete,
            icon: <Trash />,
            dataTestid: "deleteButton",
        },
    ];

    const menuFlag: Action[] = [
        user.is_organization_admin
            ? {
                  name: t("matrixPage.required"),
                  action: () => {
                      if (pk && id) {
                          dispatch(markSingleCompetence({ itemPk: pk, matrixId: id, learning_requirement_status: 20 }));
                      }
                  },
                  icon: <Flag className={styles.necessaryCheck} />,
              }
            : null,
        {
            name: t("matrixPage.addToKanban"),
            action: () => {
                if (pk && id) {
                    dispatch(markSingleCompetence({ itemPk: pk, matrixId: id, learning_requirement_status: 10 }));
                }
            },
            icon: <Flag className={styles.toLearn} />,
        },
        {
            name: t("matrixPage.inactive"),
            action: () => {
                if (pk && id) {
                    dispatch(markSingleCompetence({ itemPk: pk, matrixId: id, learning_requirement_status: 0 }));
                }
            },
            icon: <Flag className={styles.incorrectCheck} />,
        },
    ].filter((button) => button !== null) as Action[];

    const handleAdd = () => {
        setShowAddTileModal(!showAddTileModal);
    };

    const toogleFlagAction = () => {
        setShowFlagMenu(!showFlagMenu);
    };

    const toogleMenuAction = () => {
        setShowMenu(!showMenu);
    };

    const handleCompetencesLearning = () => {
        if (item.pk && id) {
            item.learning_status === true
                ? dispatch(learnSingleCompetence({ itemPk: item.pk, matrixId: id, learning_status: false }))
                : dispatch(learnSingleCompetence({ itemPk: item.pk, matrixId: id, learning_status: true }));
        }
    };

    let courses: Array<{ link: string; desc: string }> = [];
    if (item?.pk && coursesObject[item.pk]) {
        courses = coursesObject[item.pk].map((item) => ({
            link: item.link,
            desc: item.title,
        }));
    }

    const descriptionCount = description?.split(" ").length;

    if (!id) return null;

    return is_empty ? (
        <div className={styles.emptyItemContainer}>
            {user.is_organization_admin && (
                <Button data-testid="addButton" variant="primary" icon={<Plus />} onClick={handleAdd}>
                    {t("matrixPage.addCompetence")}
                </Button>
            )}
            <AddTileModal
                item={item}
                isOpen={showAddTileModal}
                roles={roles}
                matrixId={id}
                areas={areasArray}
                is_last_one={is_last_one}
                toggleOpen={() => setShowAddTileModal(!showAddTileModal)}
            />
        </div>
    ) : (
        <>
            <div className={styles.container} style={{ backgroundImage: getColor().gradiend }}>
                {is_editing ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Header
                            competence={item}
                            areas={matchedBadges}
                            menuActions={user.is_organization_admin ? menuItems : []}
                            toogleMenuAction={toogleMenuAction}
                            isMenuOpen={showMenu}
                            isFlagMenuOpen={showFlagMenu}
                            flagMenu={menuFlag}
                            toogleFlagAction={toogleFlagAction}
                            isTemplate={isTemplate}
                        />
                        <div className={styles.descriptionContainer}>
                            {description && descriptionCount && (
                                <p className={styles.description}>
                                    {descriptionCount <= 12 ? (
                                        description
                                    ) : (
                                        <TruncatedTextWithTooltip
                                            visibleWords={12}
                                            text={description}
                                            bgimage={getColor().gradiend}
                                            color="#344054"
                                            fontSize="14px"
                                        />
                                    )}
                                </p>
                            )}
                        </div>
                        {/* <div
                            style={{
                                width: "100%",
                                height: "1px",
                                marginBottom: "16px",
                                marginTop: "16px",
                                backgroundColor: getColor().color,
                            }}
                        /> */}
                        <div className={styles.bottomContainer}>
                            {!!courses.length && (
                                <ul className={styles.coursesContainer}>
                                    {courses.map((course, i) => {
                                        return (
                                            <li key={i} className={styles.courseWrapper}>
                                                <Certificate className={styles.courseIcon} />
                                                <a className={styles.courseLink} href={course.link}>
                                                    {course.desc}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                            {!isTemplate && (
                                <Tooltip
                                    title={
                                        item.learning_status === true
                                            ? t("matrixPage.learnedCompetenceTooltip")
                                            : t("matrixPage.unlearnedCompetenceTooltip")
                                    }
                                    placement="bottom-start"
                                >
                                    <button
                                        aria-controls="popover"
                                        aria-haspopup="true"
                                        className={
                                            item.learning_status === true
                                                ? styles.learnButtonCorrect
                                                : styles.learnButton
                                        }
                                        onClick={handleCompetencesLearning}
                                    >
                                        <Check
                                            className={
                                                item.learning_status === true
                                                    ? styles.learnButtonCheckCorrect
                                                    : styles.learnButtonCheck
                                            }
                                        />
                                    </button>
                                </Tooltip>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Tile;
