import React, { ButtonHTMLAttributes, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { isMobile } from "react-device-detect";

import { HTML5Backend } from "react-dnd-html5-backend";

import { TouchBackend } from "react-dnd-touch-backend";

import { DndProvider } from "react-dnd";

import Slider from "react-slick";

import { DraggableItem } from "../DragableItem/DragableItem";

import { Competence } from "../../api/types";

import { AppDispatch, RootState } from "../../store/store";

import { moveSingleCompetence } from "../../store/Competences/competencesSlice";

import { MoveItemArgs } from "../DragableItem/DraggableItem.types";

import { Counter } from "../Counter/Counter";

import { ReactComponent as ChevronLeft } from "../../assets/icons/24x24/chevron-left.svg";
import { ReactComponent as Plus } from "../../assets/icons/24x24/plus.svg";

import { GeneratedLevelData, generateLevelsData } from "../../utils/mapper";

import { Button } from "../Button/Button";

import { AddTileModal } from "../Tile/AddTileModal/AddTileModal";

import { arabicToRoman } from "../../utils/arabicToRoman";

import { DraggableGridProps, DropLocation } from "./DraggableGrid.types";
import styles from "./DraggableGrid.module.scss";

const Backend = isMobile ? TouchBackend : HTML5Backend;

const filterItems = (
    item: Competence,
    filters: { tiles: Array<number>; areas: Array<number>; learned: "learned" | "all" | "notLearned" },
) => {
    if (
        item.is_empty ||
        (item.area &&
            filters.tiles.includes(item.area) &&
            (item.attitudes?.some((itemBadge) => filters.areas.includes(itemBadge)) || item.attitudes?.length === 0))
    ) {
        return true;
    }
    return false;
};

const secondMenuFilter = (
    item: Competence,
    filters: { tiles: Array<number>; areas: Array<number>; learned: "learned" | "all" | "notLearned" },
) => {
    const learnedStatus = filters.learned === "learned" ? true : filters.learned === "notLearned" ? false : undefined;

    if (item.learning_status === learnedStatus || learnedStatus === undefined) {
        return true;
    }
    return false;
};

export const DraggableGrid: React.FC<DraggableGridProps> = ({
    filters,
    setSliderRef,
    indexCurrentSlide,
    setIndexCurrentSlide,
    shouldRenderColumn,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { id, areas, roles, isTemplate } = useSelector((state: RootState) => state.currentMatrix);
    const { isEditing, levels, skipSublevels } = useSelector((state: RootState) => state.competences);
    const { user } = useSelector((state: RootState) => state.userStore);

    const [editedTile, setEditedTile] = useState<Competence | undefined>(undefined);
    const [lastDropLocation, setLastDropLocation] = useState<DropLocation | null>(null);

    const moveItem = (args: MoveItemArgs) => {
        const {
            draggedId,
            draggedLevel,
            draggedRowIndex,
            draggedColumnIndex,
            droppedLevel,
            droppedRowIndex,
            droppedColumnIndex,
            droppedId,
            is_last_one,
        } = args;
        if (isEditing) {
            return;
        }
        setLastDropLocation((prevState) => {
            if (prevState?.droppedId !== droppedId) {
                // have to be here for now
            } else if (
                prevState &&
                prevState.droppedLevel === droppedLevel &&
                prevState.droppedRowIndex === droppedRowIndex &&
                prevState.droppedColumnIndex === droppedColumnIndex &&
                prevState.droppedId === droppedId
            ) {
                return prevState;
            }
            if (id) {
                if (droppedId) {
                    dispatch(
                        moveSingleCompetence({
                            matrixId: id,
                            id: draggedId,
                            id_taken_spot: droppedId,
                        }),
                    );
                } else {
                    dispatch(
                        moveSingleCompetence({
                            matrixId: id,
                            id: draggedId,
                            level: droppedLevel,
                            row_index: droppedRowIndex,
                            column_index: droppedColumnIndex,
                            is_last_one: is_last_one,
                        }),
                    );
                }
            }
            return {
                droppedId,
                droppedLevel,
                droppedRowIndex,
                droppedColumnIndex,
            };
        });
    };

    const NextArrow = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
        const { onClick } = props;

        const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
            setIndexCurrentSlide((prev) => prev + 1);

            if (onClick) {
                onClick(event);
            }
        };

        return (
            <button
                className={styles.nextArrow}
                onClick={handleClick}
                disabled={levelsData.length - 4 === indexCurrentSlide}
            >
                {/* TODO WHEN WE GET CHEVRON RIGHT */}
                <ChevronLeft style={{ transform: "rotate(180deg)" }} />
            </button>
        );
    };

    const PrevArrow = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
        const { onClick } = props;

        const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
            setIndexCurrentSlide((prev) => prev - 1);

            if (onClick) {
                onClick(event);
            }
        };

        return (
            <button className={styles.prevArrow} onClick={handleClick} disabled={indexCurrentSlide < 1}>
                <ChevronLeft />
            </button>
        );
    };

    const levelsData = generateLevelsData(levels);
    const shouldRenderArrow = levelsData.length > 4;

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: false,
        prevArrow: shouldRenderArrow ? <PrevArrow /> : undefined,
        nextArrow: shouldRenderArrow ? <NextArrow /> : undefined,
        beforeChange: (current: number, next: number) => setIndexCurrentSlide(next),
    };

    const getColumnsContainerStyle = (level: GeneratedLevelData, index: number) => {
        const title = level.name.split(" ")[0];

        if (levelsData[index - 1]?.name.includes(title)) {
            if (levelsData[index + 1]?.name.includes(title)) {
                return "middleColumnTitleContainer";
            }
            return "lastColumnTitleContainer";
        }
        if (levelsData[index + 1]?.name.includes(title)) {
            return "firstColumnTitleContainer";
        }
        return "aloneColumnTitleContainer";
    };

    const getEmptyItemData = (level: GeneratedLevelData) => {
        return level.competences.find((competence) => competence.is_empty);
    };

    const matrixTable = levelsData.map((level, i) => {
        const style = getColumnsContainerStyle(level, i) ?? "";
        const emptyItemData = getEmptyItemData(level);
        const title = level.name.split(" ")[0].replace(/_/g, " ");
        return (
            <div key={i}>
                <div className={styles[style]}>
                    {(style === "firstColumnTitleContainer" || style === "aloneColumnTitleContainer") && (
                        <div className={styles.titleWrapper}>
                            <p className={styles.levelName}>{title}</p>
                            <Counter counter={level.count_user_competence} max={level.count_all_competence} />
                        </div>
                    )}
                </div>
                {!skipSublevels && (
                    <div className={styles.levelNameWrapper}>
                        <p className={styles.levelName}>{arabicToRoman(level.name)}</p>
                        {user.is_organization_admin && !isTemplate && (
                            <Button
                                icon={<Plus />}
                                variant="icon"
                                size="large"
                                onClick={() => setEditedTile(emptyItemData)}
                            />
                        )}
                    </div>
                )}
                <DndProvider backend={Backend}>
                    <div className={styles.itemsContainer}>
                        {level.competences
                            .filter((item) => filterItems(item, filters))
                            .filter((item) => secondMenuFilter(item, filters))
                            .map((item, columnIndex) => {
                                const isLastOne = level.competences.length === columnIndex + 1;

                                return (
                                    <DraggableItem
                                        key={item?.pk || `${item.level}-${item.row_index}-${item.column_index}`}
                                        item={item}
                                        is_last_one={isLastOne}
                                        onDrop={moveItem}
                                    />
                                );
                            })}
                    </div>
                </DndProvider>
            </div>
        );
    });

    return (
        <>
            <div className={styles.columnsContainer}>
                {shouldRenderColumn ? (
                    <Slider ref={(slider) => setSliderRef(slider)} {...settings} className={styles.slider}>
                        {matrixTable}
                    </Slider>
                ) : (
                    <div className={styles.singleStandMatrix}>{matrixTable}</div>
                )}
            </div>
            {editedTile && id && (
                <AddTileModal
                    areas={areas}
                    roles={roles}
                    item={editedTile}
                    isOpen={!!editedTile}
                    matrixId={id}
                    is_last_one={true}
                    toggleOpen={() => setEditedTile(undefined)}
                />
            )}
        </>
    );
};
