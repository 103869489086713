import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import { Provider } from "react-redux";

import store from "../src/store/store";

import reportWebVitals from "./reportWebVitals";
import { Notifications } from "./components/Notifications/Notifications";

import Pages from "./pages";

import "./i18n";

const loadEnvironmentScripts = () => {
    const env = window.APP_CONFIG.REACT_APP_ENV;

    if (env === "production") {
        const headScript = document.createElement("script");
        headScript.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-T3XPSBZ2');
      `;
        document.head.insertBefore(headScript, document.head.firstChild);

        const noscript = document.createElement("noscript");
        noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T3XPSBZ2"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
        document.body.insertBefore(noscript, document.body.firstChild);
    }
};

loadEnvironmentScripts();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={store}>
        <Pages />
        <Notifications />
    </Provider>,
);
reportWebVitals();
