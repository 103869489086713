import React, { FC, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";

import { AppDispatch, RootState } from "../../store/store";

import { Button } from "../Button/Button";
import { regenerateSingleCompetence } from "../../store/Competences/competencesSlice";

import { toggleRegenerateCompetenceModal } from "../../store/Modal/modalSlice";
import { Competence } from "../../api/types";

import Modal from "../Modal/Modal";

import styles from "./RenegerateCompetenceModal.module.scss";

const RenegerateCompetenceModal: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [textState, setTextState] = useState<string>("");
    const { regenerateCompetenceModalOpen } = useSelector((state: RootState) => state.modal);

    const handleCloseModal = () => {
        dispatch(toggleRegenerateCompetenceModal({ isOpen: false, item: {} as Competence }));
    };

    const handleSubmit = () => {
        dispatch(
            regenerateSingleCompetence({
                item: regenerateCompetenceModalOpen.item,
                desc: textState,
            }),
        );
        handleCloseModal();
    };

    return (
        <Modal
            title={t("matrixPage.regenerateModal.title")}
            isOpen={regenerateCompetenceModalOpen.isOpen}
            actionButtons={{
                onClose: { action: handleCloseModal },
                onSubmit: (
                    <Button variant="secondary" onClick={handleSubmit}>
                        {t("matrixPage.regenerateModal.update")}
                    </Button>
                ),
            }}
        >
            <TextField
                multiline
                label={<>{t("matrixPage.regenerateModal.description")}</>}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: "#7F56D9",
                        },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                        color: "#7F56D9",
                    },
                }}
                inputProps={{
                    style: {
                        height: "130px",
                    },
                }}
                value={textState}
                className={styles.editableTextField}
                onChange={(e) => setTextState(e.target.value)}
            />
        </Modal>
    );
};

export default RenegerateCompetenceModal;
